.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ag-header-cell-label .ag-header-cell-text {
  white-space: pre-wrap !important;
  text-align: center !important;
}

.bottomPush {
  position: absolute;
  bottom: 0;
  text-align: center;
}

.queLogo {
  padding: 0;
  margin: 0;
  height: 40px;
}

.fullscreen.fullscreen-enabled {
  background: white;
}

.fullscreen {
  width: 100%;
}

:fullscreen .ag-root {
  padding: 16px;
}

.final-price-set {
}

.final-price-set .ag-cell {
  background-color: #ecf7ec;
}

.ag-cell > .Mui-disabled {
  opacity: 1 !important;
}

.ag-header-cell-label {
  justify-content: center;
}

.MuiChip-labelMedium {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

p.input-error {
  color: red;
  font-size: 12px;
  margin: 0;
  position: absolute;
  font-weight: 400;
}

.ag-cell[col-id="finance_check"],
.ag-cell[col-id="legal_check"],
.ag-cell[col-id="qsu_check"] {
  display: flex;
  justify-content: center;
  align-items: center;
}

.departmentWrapper {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
}

.departmentList {
  max-width: 100%;
  width: 400px;
}
.listWrap {
  border: solid 1px ;
  border-color: #00000054;
  border-radius: 6px;
  margin-top: 5px;
}
.departmentView {
  width: calc(100% - 400px);
  padding: 0 20px;
  justify-content: flex-start;
}

.creating {
  margin-left: 40px;
}

.depWrapper {
  display: flex;
  flex-wrap: wrap;
  border: solid 1px ;
  border-color: #00000054;
  border-radius: 6px;
}

.depWrapper-inner {
  width: 50%;
  min-width: 300px;
}
.depWrapper-inner:first-child {
  border-right: solid 1px;
  border-color: #00000054;
}

.depWrapper-inner .title {
  border-bottom: solid 1px;
  border-color: #00000054;
  padding-bottom: 10px;
}

.createArea {
  width: 100%;
  margin-top: 40px;
}

.poly-view {
  text-decoration: underline;
  cursor: pointer;
}

.leaflet-container {
  height: 300px;
  width: 100%;
}
#mapContainer {
  position: relative;

}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2222222;
  background: #fff;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  border: solid 1px;
}